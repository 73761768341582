import { Box, Typography } from "@mui/material";
import { FC, memo } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "gatsby-theme-material-ui";

export const HeroSection: FC = memo(() => {
  return (
    <Box display="grid">
      <StaticImage
        style={{
          gridArea: "1/1",
          minHeight: 375,
          maxHeight: "70vh",
        }}
        layout="fullWidth"
        alt=""
        src="../images/house.JPG"
        loading="eager"
        objectPosition="50% 100%"
        placeholder="blurred"
        transformOptions={{
          duotone: { opacity: 40, highlight: "#000000", shadow: "#000000" },
        }}
      />
      <Box
        display="grid"
        gridArea="1/1"
        style={{
          position: "relative",
          placeItems: "center",
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography
            variant="h1"
            color="primary"
            align="center"
            sx={{
              textShadow:
                "0px 2px 4px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%)",
            }}
          >
            Вила Вили Ла Коста
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            align="center"
            sx={{
              textShadow:
                "0px 2px 4px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%)",
            }}
          >
            Семеен хотел
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              sx={{ mt: 2 }}
              to="#contact"
              onSubmit={() => {
                window?.gtag("event", "hero_request_form", { value: 1 });
              }}
            >
              Направете Запитване
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
