import { FC } from "react";
import {
  Facebook,
  Phone,
  AccountBalance,
  LocationOn,
} from "@mui/icons-material";
import { Box, Grid, Typography, Container } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const ContactSection: FC = () => (
  <Box sx={{ backgroundColor: "rgb(247, 250, 255)" }}>
    <Container maxWidth="xl">
      <Box sx={{ pt: 5, pb: 5 }}>
        <Typography variant="h4" textAlign="center" gutterBottom sx={{ mb: 5 }}>
          Данни за контакт
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} display="flex">
            <Phone color="primary" />
            <Typography sx={{ pl: 1 }} gutterBottom>
              <Link to={"tel:+359-88-586-0719"} color="inherit">
                +359-88-586-0719
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} display="flex">
            <LocationOn color="primary" />
            <Typography sx={{ pl: 1 }} gutterBottom>
              ул. Преображенска №6 8277 Лозенец Бургас
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} display="flex">
            <Facebook color="primary" />
            <OutboundLink
              href="https://www.facebook.com/VilaViliLaCosta"
              style={{ color: "inherit" }}
            >
              <Typography sx={{ pl: 1 }} gutterBottom>
                @VilaViliLaCosta
              </Typography>
            </OutboundLink>
          </Grid>
          <Grid item xs={12} md={3} display="flex">
            <AccountBalance color="primary" />
            <Box>
              <Typography sx={{ pl: 1 }}>BIC - UNCRBGSF</Typography>
              <Typography sx={{ pl: 1 }}>
                IBAN - BG09UNCR70001524034032
              </Typography>
              <Typography sx={{ pl: 1 }}>Ваклина Дичева</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Box>
);
