import Home from "@mui/icons-material/Home";
import BathtubOutlined from "@mui/icons-material/BathtubOutlined";
import Tv from "@mui/icons-material/Tv";
import Wifi from "@mui/icons-material/Wifi";
import LocalParking from "@mui/icons-material/LocalParking";
import AcUnit from "@mui/icons-material/AcUnit";
import Kitchen from "@mui/icons-material/Kitchen";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";
import { CardActionArea } from "gatsby-theme-material-ui";
import { ReactNode, FC, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const RoomCard: FC<{
  type: string;
  bodyText: string;
  area: number;
  image: ReactNode;
}> = ({ type, bodyText, area, image }) => (
  <Card>
    <CardActionArea>{image}</CardActionArea>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        {type}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {bodyText}
      </Typography>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      <Box display="flex" alignItems="center">
        <Home fontSize="small" />
        <Box ml={1}>
          <Typography variant="body1">{area} кв. м</Typography>
        </Box>
        <Box flex={1} />
        {Object.values({
          AcUnit,
          BathtubOutlined,
          Tv,
          Kitchen,
          Wifi,
          LocalParking,
        }).map((AmentityIcon, index) => (
          <Box key={index} ml={index > 0 ? 1 : 0}>
            <AmentityIcon />
          </Box>
        ))}
      </Box>
    </CardContent>
  </Card>
);

export const RoomsSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true });
  useEffect(() => {
    if (inView) {
      window?.gtag?.("event", "view_room_section");
    }
  }, [inView]);
  return (
    <Box display="flex" flexDirection="column" sx={{ pt: 5, pb: 5 }} ref={ref}>
      <Box display="flex" justifyContent="center">
        <Typography
          color="primary"
          sx={({ palette }) => ({
            borderBottom: `3px solid ${palette.primary.main}`,
            lineHeight: 1,
            mb: 2,
          })}
          variant="h6"
          fontWeight="bold"
        >
          НАШИТЕ СТАИ
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item lg={4}>
          <RoomCard
            type="Двойна"
            bodyText="Стая с две легла и баня. Едното легло от което е персон и половина, второто е персон. Стаята е подходяща за малки семейства."
            area={15}
            image={
              <StaticImage
                width={400}
                height={300}
                src="../images/double-room-one.jpg"
                alt="double room"
              />
            }
          />
        </Grid>
        <Grid item lg={4}>
          <RoomCard
            type="Тройна"
            bodyText="Голяма стая с две легла и баня. Едното легло от което е спалня, а другото е персон и половина. Подходяща за малки семейства."
            area={20}
            image={
              <StaticImage
                width={400}
                height={300}
                src="../images/tripple-room.jpg"
                alt="tripple room"
              />
            }
          />
        </Grid>
        <Grid item lg={4}>
          <RoomCard
            type="Апартамент"
            bodyText="С две стаи и голяма баня. Едната стая на апартамента е спалня, а в другата има две легла от които едното е персон и половина."
            area={30}
            image={
              <StaticImage
                width={400}
                height={300}
                src="../images/apartment-room.jpg"
                alt="apartment"
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
