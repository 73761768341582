import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC, lazy, Suspense, useEffect, useState } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

const DialogComponent = lazy(() =>
  import("./GalleryDialog").then((m) => ({ default: m.GalleryDialog }))
);

export const GallerySection: FC<{ images: IGatsbyImageData[] }> = ({
  images,
}) => {
  const [open, setOpen] = useState(null);
  const isDOM =
    typeof window !== "undefined" &&
    window.document &&
    window.document.documentElement;
  const { ref, inView } = useInView({ triggerOnce: true });
  const handleOnImageClick = (image, index) => {
    setOpen(image);
    window?.gtag?.("event", "view_gallery_image", { value: index });
  };
  useEffect(() => {
    if (inView) {
      window?.gtag?.("event", "view_gallery_section");
    }
  }, [inView]);
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ mt: 5, mb: 5 }}
        ref={ref}
      >
        <Box display="flex" justifyContent="center">
          <Typography
            color="primary"
            sx={({ palette }) => ({
              borderBottom: `3px solid ${palette.primary.main}`,
              lineHeight: 1,
              mb: 2,
            })}
            variant="h6"
            fontWeight="bold"
          >
            ГАЛЕРИЯ
          </Typography>
        </Box>
        <ImageList cols={3} gap={8} variant="masonry">
          {images.map((image, index) => (
            <ImageListItem onClick={() => handleOnImageClick(image, index)}>
              <GatsbyImage image={image} style={{ borderRadius: 8 }} alt={""} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {isDOM && inView && (
        <Suspense fallback={null}>
          <DialogComponent image={open} onClose={() => setOpen(null)} />
        </Suspense>
      )}
    </>
  );
};
