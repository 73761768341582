import { Grid, Paper, Box, Typography, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const ContactUsSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const [hasInteracted, setHasInteracted] = useState(false);
  const [arrival_date, setArrivalDate] = useState(null);
  useEffect(() => {
    if (inView) {
      window?.gtag?.("event", "view_contact_section");
    }
  }, [inView]);
  return (
    <Box
      ref={ref}
      id="contact"
      display="flex"
      flexDirection="column"
      sx={{ mt: 5, mb: 5 }}
    >
      <Box display="flex" justifyContent="center">
        <Typography
          color="primary"
          sx={({ palette }) => ({
            borderBottom: `3px solid ${palette.primary.main}`,
            lineHeight: 1,
            mb: 2,
          })}
          variant="h6"
          fontWeight="bold"
        >
          СВЪРЖЕТЕ СЕ С НАС
        </Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item lg={6} sm={12}>
          <Paper>
            <Box p={2}>
              <Typography variant="h4" gutterBottom>
                Свържете се с нас за всичко
              </Typography>
              <form
                action="/api/submit-contact-form"
                method="POST"
                onSubmit={() => {
                  window?.gtag?.("event", "submit_form", { value: 1 });
                }}
                onChange={() => {
                  if (!hasInteracted) {
                    window?.gtag?.("event", "has_interacted_contact_form");
                    setHasInteracted(true);
                  }
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      type="date"
                      name="arrival_date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Настаняване"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{
                        min: "2022-02-06",
                        max: "2030-12-31",
                      }}
                      onChange={(e) => setArrivalDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="date"
                      name="departure_date"
                      label="Напускане"
                      fullWidth
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: arrival_date,
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  type="number"
                  name="guests"
                  label="Гости"
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  name="name"
                  label="Име"
                  fullWidth
                  margin="normal"
                  required
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      type="email"
                      name="email"
                      label="Е-поща"
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="tel"
                      name="phone_number"
                      label="Телефон"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <TextField
                  name="message"
                  label="Съобщение"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  required
                />
                <Box>
                  <Button type="submit" variant="contained">
                    Изпрати
                  </Button>
                </Box>
                <Typography variant="caption">
                  Като натиснете върху „изпрати“, Вие се съгласявате с{" "}
                  <a href="/privacy-policy" target="_blank">
                    политиката за поверителност
                  </a>
                </Typography>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={6} sm={12} sx={{ width: "100%" }}>
          <Paper sx={{ height: "100%", minHeight: "350px" }}>
            {inView && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2955.1183573854137!2d27.800679315751662!3d42.21191495243628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a12d05008c7775%3A0x893405732f4d8486!2z0JLQuNC70LAg0JLQuNC70Lgg0JvQsCDQmtC-0YHRgtCw!5e0!3m2!1sen!2sdk!4v1623606917516!5m2!1sen!2sdk"
                width="100%"
                height="100%"
                style={{ border: 0, borderRadius: 5 }}
                allowFullScreen={false}
                loading="lazy"
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
