import BeachAccessTwoTone from "@mui/icons-material/BeachAccessTwoTone";
import LocalParkingTwoTone from "@mui/icons-material/LocalParkingTwoTone";
import OutdoorGrillTwoTone from "@mui/icons-material/OutdoorGrillTwoTone";
import DeckTwoTone from "@mui/icons-material/DeckTwoTone";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import { graphql, PageProps } from "gatsby";
import { FC, memo } from "react";
import { PricesSection } from "../components/PricesSection";
import { RoomsSection } from "../components/RoomsSection";
import { getImage } from "gatsby-plugin-image";
import { GallerySection } from "../components/GallerySection";
import { ContactSection } from "../components/ContactSection";
import { ContactUsSection } from "../components/ContactUsSection";
import { HeroSection } from "../components/HeroSection";
import { Seo } from "../components/Seo";

const IndexPage: FC<PageProps> = memo(({ data }) => {
  const images = data.markdownRemark.frontmatter.galleryImages.map(getImage);
  return (
    <>
      <Seo pageTitle={"Почивка на минути от морето"} />
      <HeroSection />
      <Container>
        <Box display="flex" flexDirection="column" sx={{ mt: 5, mb: 5 }}>
          <Box display="flex" justifyContent="center">
            <Typography
              color="primary"
              sx={({ palette }) => ({
                borderBottom: `3px solid ${palette.primary.main}`,
                lineHeight: 1,
                mb: 2,
              })}
              variant="h6"
              fontWeight="bold"
            >
              ЗА ВИЛАТА
            </Typography>
          </Box>
          <Typography textAlign="center" variant="h6" fontWeight={"normal"}>
            {data.markdownRemark.frontmatter.about_us}
          </Typography>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "rgb(247, 250, 255)" }}>
        <Container>
          <RoomsSection />
        </Container>
      </Box>
      <Container>
        <Box display="flex" flexDirection="column" sx={{ mt: 5, mb: 5 }}>
          <Box display="flex" justifyContent="center">
            <Typography
              color="primary"
              sx={({ palette }) => ({
                borderBottom: `3px solid ${palette.primary.main}`,
                lineHeight: 1,
                mb: 2,
              })}
              variant="h6"
              fontWeight="bold"
            >
              УДОБСТВА
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <BeachAccessTwoTone style={{ fontSize: 80 }} color="primary" />
                <Typography variant="h6" textAlign="center">
                  Лесен достъп до плаж
                </Typography>
                <Typography textAlign="center">
                  Вилата се намира на 2 минути от централната плажна ивица на с.
                  Лозенец.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <DeckTwoTone style={{ fontSize: 80 }} color="primary" />
                <Typography variant="h6" textAlign="center">
                  Голям двор
                </Typography>
                <Typography textAlign="center">
                  С площ 840 кв.м. където може да се насладите на хубавото време
                  в спокойна обстановка
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <LocalParkingTwoTone style={{ fontSize: 80 }} color="primary" />
                <Typography variant="h6" textAlign="center">
                  Паркинг
                </Typography>
                <Typography textAlign="center">
                  Разполагаме със собствен паркинг за 10 коли
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <OutdoorGrillTwoTone style={{ fontSize: 80 }} color="primary" />
                <Typography variant="h6">Външен грил и лятна кухня</Typography>
                <Typography textAlign="center">
                  На Ваше разположение е лятна кухня и грил в двора, подходящи
                  за изготвяне на студени храни и барбекю
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "rgb(247, 250, 255)" }}>
        <Container>
          <PricesSection />
        </Container>
      </Box>
      <Container>
        <GallerySection images={images} />
      </Container>
      <Container>
        <Box display="flex" flexDirection="column" sx={{ mt: 5, mb: 5 }}>
          <Box display="flex" justifyContent="center">
            <Typography
              color="primary"
              sx={({ palette }) => ({
                borderBottom: `3px solid ${palette.primary.main}`,
                lineHeight: 1,
                mb: 2,
              })}
              variant="h6"
              fontWeight="bold"
            >
              ОТЗИВИ И МНЕНИЯ
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {[
              {
                text: "Когато правиш всичко с много любов, желание и полагаш огромни усилия за всеки малък детайл, се получава точно това - Вила Вили Ла Коста. Благодарим на цялото семейство Вили, Вики, Цвети, Любо и Райчето за прекрасните емоции, …",
                name: "Aleksandra Damyanova",
                rating: 5,
              },
              {
                text: "Много гостоприемни и коректни домакини. Перфектна локация - на около 2 минути от плажа. Чисти и уютни стаи. Голям двор. И не на последно място са pet friendly! Също така, комуникацията с тях онлайн беше супер бърза и удобна. Препоръчвам …",
                name: "Iva Mladenova",
                rating: 5,
              },
              {
                text: "С огромна благодарност към домакините на Vila Vili La Costa / Вила Вили Ла Коста , които ни посрещнаха с усмивка и позитивно отношение. Големият и страшен лабрадор, който е домашният ми любимец беше много щастлив от мястото за игра в …",
                name: "Petyr Petrov",
                rating: 5,
              },
              {
                text: "Много приятен хотел с чудесна, мила и добронамерена стопанска. Препоръчвам с две ръце!",
                name: "Vladislav Radev",
                rating: 5,
              },
              {
                text: "На минута от плажа.Страхотни домакини във вилата, никакъв проблем с размера на кучетата, броя на кучетата или искане да предплатим или доплатим за тях. Голям двор където да тичат с лятна кухня , барбекю и тн.",
                name: "Вероника Георгиева",
                rating: 5,
              },
              {
                text: "Едно невероятно и вълшебно място,което те грабва със своята харизма,невероятни усмивки и доброта! За първи път посещаваме толкова лъчезарни и страхотни хора. Респект! Грабнахте ни още от първият момент. И тази наша почивка при вас няма да е …",
                name: "antoniq petrova",
                rating: 5,
              },
            ].map((review, index) => (
              <Grid item md={4} key={index}>
                <Card>
                  <CardContent>
                    <Rating value={review.rating} precision={0.5} readOnly />
                    <Typography>{review.text}</Typography>
                    <Box display="flex" alignItems="center" mt={2}>
                      <Avatar>
                        {review.name.split(" ")[0][0].toUpperCase()}
                        {review.name.split(" ")[1][0].toUpperCase()}
                      </Avatar>
                      <Box ml={1}>
                        <Typography>{review.name}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <ContactUsSection />
      </Container>
      <ContactSection />
    </>
  );
});

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        about_us
        galleryImages {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default IndexPage;
